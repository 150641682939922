import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import VipLabel from '@/coms/utils/vipLabel';
import AINumberLabel from '@/coms/utils/aiNumberLabel';
import Payment from '../coms/payment';
import PriceOption from './priceOption';
import { PriceItem } from '..';
import UserHeadPortrait from '@/coms/utils/userHeadPortrait';

function Ai({
  priceList,
  currentPrice,
  show_uid,
  pay_url,
  username,
  setCurrentPrice,
}: {
  head_portrait: string;
  priceList: any[];
  currentPrice: PriceItem;
  show_uid: string;
  pay_url: string;
  username: string;
  setCurrentPrice: (item: any) => void;
}) {
  return (
    <div className={STYLE.root}>
      <div className={STYLE.top}>
        <UserHeadPortrait style={{ borderRadius: '50%', marginRight: '10px' }} />
        <div className={STYLE.top_right}>
          <div className={STYLE.top_right_top}>
            <div className={STYLE.username}>{username}</div>
            <div className={STYLE.user_show_uid}>{`ID:${show_uid}`}</div>
          </div>
          <div className={STYLE.top_right_bottom}>
            <VipLabel />
            <AINumberLabel style={{ height: '20px' }} />
          </div>
        </div>
      </div>
      <div className={STYLE.bottom}>
        <div className={STYLE.left}>
          <div className={STYLE.left_top}>
            <Icon name="AIzishu" size={16} style={{ marginRight: '4px' }} />
            对话字数使用规则
          </div>
          <div className={STYLE.left_item_list}>
            <div className={STYLE.left_item}>
              <span />
              <span>向AI大模型提问，按大模型回答字数计数</span>
            </div>
            <div className={STYLE.left_item}>
              <span />
              <span>向机器人提问，按机器人回答字数计数</span>
            </div>
            <div className={STYLE.left_item}>
              <span />
              <span>多个模型同时回答，按多个模型回答字数计数</span>
            </div>
          </div>
        </div>
        <div className={STYLE.right}>
          <div className={STYLE.right_top}>
            {priceList.map((item, index) => {
              return (
                <PriceOption
                  key={item.id}
                  selected={currentPrice.id === item.id}
                  price={item.price}
                  name={item.name}
                  title={item.title}
                  recommend={!!item.recommendText || index === 0}
                  recommendText={item.recommendText}
                  onClick={() => setCurrentPrice(item)}
                  forAI={index > 0}
                />
              );
            })}
          </div>
          <div className={STYLE.right_bottom}>
            <Payment pay_url={pay_url} price={currentPrice.price} forAI />
          </div>
        </div>
        <div className={STYLE.background_bottomRight} />
      </div>
    </div>
  );
}

export default Ai;
