import STYLE from './index.module.less';
import { modal } from '@/configs/theme';
import Icon from '@/coms/icon';
import { Button } from 'antd';
import userInfoStore from '@/stores/userInfo';
import { useEffect, useState } from 'react';
import { getPayPriceList } from '@/services/recharge';
import { useRequest } from 'ahooks';

// 会员限制弹窗
function LimitModal({ onSuccess = () => {} }) {
  const [enjoyPrice, setEnjoyPrice] = useState<{ price: number }>();

  const { data: priceList } = useRequest(getPayPriceList, {
    defaultParams: [{ show_page: 'vip' }],
  });

  useEffect(() => {
    if (!priceList) return;
    const { tab_list } = priceList;
    if (tab_list.length) {
      const list = tab_list[0]?.list || [];
      setEnjoyPrice(list.find((i: any) => i.vip_range === 2));
    }
  }, [priceList]);

  return (
    <div className={STYLE.root}>
      <div className={STYLE.background1}></div>
      <div className={STYLE.background2}></div>
      <Icon
        name={'guanbi'}
        size={20}
        fill={'#2C2C2C'}
        onClick={() => userInfoStore.updateLimitType(false)}
      />
      <div className={STYLE.title}>升级会员</div>
      <div className={STYLE.price}>
        <div className={STYLE.sign}>{'¥'}</div>
        {enjoyPrice?.price}
      </div>
      <div className={STYLE.recommendText}>3天全站畅用会员/不限对话字数 </div>
      <div className={STYLE.recommendList}>
        <div className={STYLE.item}>
          <div>
            <Icon name={'danseAI'} size={26} fill={'rgba(125, 125, 238, 1)'} />
          </div>
          <div>3天内</div>
          <div>不限次对话</div>
        </div>
        <div className={STYLE.item}>
          <div>
            <Icon name={'moxing'} size={26} fill={'rgba(125, 125, 238, 1)'} />
          </div>
          <div>所有模型</div>
          <div>使用无限制</div>
        </div>
        <div className={STYLE.item}>
          <div>
            <Icon name={'buju'} size={26} fill={'rgba(125, 125, 238, 1)'} />
          </div>
          <div>布局切换</div>
          <div>使用无限制</div>
        </div>
        <div className={STYLE.item}>
          <div>
            <Icon name={'duihua-b8gf8k5e'} size={26} fill={'rgba(125, 125, 238, 1)'} />
          </div>
          <div>所有AI机器人</div>
          <div>使用无限制</div>
        </div>
      </div>
      <div className={STYLE.bottom}>
        <span>
          <Icon name={'duigouicon'} size={20} fill={'rgba(191, 191, 255, 1)'} />
          1对1专属客户服务
        </span>
        <span>
          <Icon name={'duigouicon'} size={20} fill={'rgba(191, 191, 255, 1)'} />
          快速开正规发票
        </span>
      </div>
      <Button
        rootClassName={STYLE.btn}
        type="primary"
        block
        onClick={() => {
          userInfoStore.checkRights(undefined, ['vip'], { isMessage: false });
          onSuccess();
        }}
      >
        立即开通
      </Button>
    </div>
  );
}

export default LimitModal;

export function commonShowLimitModal({
  onSuccess,
  onClose,
  type,
}: {
  onSuccess: () => void;
  onClose: () => void;
  type: 'vip' | 'wordNum' | 'num' | false;
}) {
  return modal.info({
    content: <LimitModal onSuccess={onSuccess} />,
    closeIcon: false,
    footer: null,
    icon: null,
    centered: true,
    width: 515,
    className: 'clearAntdModalContentPadding hiddenAntdModalContentOverflow',
    afterClose: onClose,
  });
}
