import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import clx from 'classnames';

function PriceOption({
  selected,
  recommend,
  add_ai_word_num,
  add_ai_num,
  price,
  name,
  sign = '¥',
  recommendText = '全站模型、AI畅用',
  onClick,
}: {
  selected: boolean;
  recommend: boolean;
  add_ai_word_num: number;
  add_ai_num: number;
  price: number;
  name: string;
  title: number;
  sign?: string;
  recommendText: string;
  onClick: () => void;
}) {
  const instructions = [
    { icon: 'moxing', text: '所有模型可用' },
    { icon: 'danseAI', text: '所有机器人可用' },
  ];

  return (
    <div
      className={clx(STYLE.root, {
        [STYLE.root_recommend]: recommend,
        [STYLE.root_selected]: selected,
      })}
      onClick={onClick}
    >
      <div className={STYLE.top}>{name}</div>
      <div className={STYLE.middle}>
        <div className={STYLE.price}>
          <div className={STYLE.sign}>{sign}</div>
          {price}
        </div>
      </div>
      <div className={clx(STYLE.gift, { [STYLE.gift_recommend]: recommend })}>
        {/*<Icon name={'danseAI'} size={14} style={{ marginRight: '4px' }} />*/}
        {/* {add_ai_word_num
          ? `赠送 ${add_ai_word_num.toLocaleString('en-US')} 对话字数`
          : '3天内 不限对话字数'} */}
        {add_ai_word_num ? `赠 ${add_ai_word_num.toLocaleString('en-US')} 字数` : '3天内 不限字数对话'}
      </div>
      <div className={STYLE.bottom}>
        {instructions.map((item, index) => {
          return (
            <div key={index} className={STYLE.item}>
              <span className={STYLE.dot} />
              {item.text}
            </div>
          );
        })}
      </div>
      {recommend ? <div className={STYLE.recommend}>{recommendText}</div> : null}
      {selected ? (
        <div className={STYLE.selected}>
          <Icon name="wancheng" size={20} fill={'#fff'} />
        </div>
      ) : null}
    </div>
  );
}

export default PriceOption;
