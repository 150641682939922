import { useRef, useState } from 'react';
import { LoginFormField, PageProps, AgreementCheck, PhoneAndCodeFormItem } from '../common';
import { useEventListener, useInterval, useRequest } from 'ahooks';
import {
  checkQQLoginStatus,
  checkWechatLoginStatus,
  getQQLoginUrl,
  getWechatLoginUrl,
  phoneCodeLogin,
  phonePasswordLogin,
} from '@/services/user';
import { Button, Input, Form, QRCode, Tabs, Spin } from 'antd';
import STYLE from './index.module.less';
import clx from 'classnames';
import Icon from '@/coms/icon';
import { message } from '@/configs/theme';
import { getOpenWinCommonSetting } from '@/utils/common';
import Image from 'next/image';
import { LoadingOutlined } from '@ant-design/icons';

export default function LoginPage({
  setCurPage,
  onSuccess,
  className,
  phone,
  setPhone,
}: PageProps) {
  const [type, setType] = useState<'wechat' | 'phoneCode' | 'account'>('wechat');
  const {
    data: wechatLoginData,
    loading: wechatLoginDataLoading,
    run: refreshWechatLoginData,
  } = useRequest(getWechatLoginUrl, {
    ready: type === 'wechat',
    refreshDeps: [type],
  });

  const [wechatQrCodeExpired, setWechatQrCodeExpired] = useState(false);
  useInterval(() => {
    if (wechatLoginData && Date.now() >= wechatLoginData.expire_time) {
      setWechatQrCodeExpired(true);
    } else {
      setWechatQrCodeExpired(false);
    }
  }, 1000);

  useRequest(checkWechatLoginStatus, {
    ready: type === 'wechat' && wechatLoginData && !wechatQrCodeExpired,
    refreshDeps: [type, wechatLoginData, wechatQrCodeExpired],
    defaultParams: [{ scene_str: wechatLoginData?.scene_str || '' }],
    pollingInterval: 1000,
    onSuccess: (res) => {
      if (res) {
        message.success('登录成功');
        onSuccess();
      }
    },
  });

  const { runAsync: runGetQQLoginUrl, loading: getQQLoginUrlLoading } = useRequest(getQQLoginUrl, {
    manual: true,
  });
  const qqWinRef = useRef<Window | null>(null);
  useEventListener('message', (e) => {
    // console.log('message', e);
    if (!e.data) return;
    const { type, query } = e.data;
    if (type === 'loginSuccess' && query?.code) {
      checkQQLoginStatus({ code: query.code }).then((res) => {
        try {
          qqWinRef.current?.close();
        } catch (e) {
          console.error(e);
        }
        if (res) {
          message.success('登录成功');
          onSuccess();
        } else {
          message.error('未知错误');
        }
      });
    }
  });

  const [phoneCodeForm] = Form.useForm<Pick<LoginFormField, 'phone' | 'code'>>();
  const { runAsync: runPhoneCodeLogin, loading: phoneCodeLoginLoading } = useRequest(
    phoneCodeLogin,
    { manual: true },
  );

  const [accountForm] = Form.useForm<Pick<LoginFormField, 'phone' | 'password'>>();
  const { runAsync: runPhonePasswordLogin, loading: phonePasswordLoginLoading } = useRequest(
    phonePasswordLogin,
    { manual: true },
  );

  const [agreementCheckForWechat, setAgreementCheckForWechat] = useState(true);

  return (
    <div className={clx(className, STYLE.loginPage)}>
      <div className={STYLE.view}>
        {type === 'wechat' ? (
          <div className={STYLE.qrCodeType}>
            <h5>
              <Icon name="weixin" />
              微信登录
            </h5>
            {/* <QRCode
              className={STYLE.qrCode}
              size={160}
              value={wechatLoginData?.qr_src || 'nothing'}
              status={(() => {
                if (wechatLoginDataLoading) {
                  return 'loading';
                } else if (wechatQrCodeExpired) {
                  return 'expired';
                } else {
                  return 'active';
                }
              })()}
              onRefresh={refreshWechatLoginData}
            /> */}
            <div className={STYLE.qrCode}>
              <Spin
                className={STYLE.spin}
                style={{width: 160, height: 160}}
                indicator={<LoadingOutlined spin />}
                spinning={wechatLoginDataLoading}
              >
                {wechatLoginData ? (
                  <Image
                    src={wechatLoginData.qr_src}
                    alt="wechat qr code"
                    width={160}
                    height={160}
                  />
                ) : null}

                {wechatQrCodeExpired && (
                  <div className="flexCenter">
                    <div>
                      <p>二维码过期了</p>
                      <Button type="text" size="small" onClick={() => refreshWechatLoginData()}>
                        点击刷新
                      </Button>
                    </div>
                  </div>
                )}

                {!agreementCheckForWechat ? (
                  <div className={STYLE.mask}>
                    登录需同意勾选
                    <br />
                    以下相关协议
                  </div>
                ) : null}
              </Spin>
            </div>

            <p>微信扫码登录</p>
            <AgreementCheck
              checked={agreementCheckForWechat}
              onChange={(e) => setAgreementCheckForWechat(e.target.checked)}
              style={{ marginTop: 20 }}
            />
          </div>
        ) : (
          <div className={STYLE.formType}>
            <Tabs
              className={STYLE.tabs}
              centered
              defaultActiveKey="phoneCode"
              onChange={(key) => {
                if (key === 'phoneCode') {
                  phoneCodeForm.setFieldValue('phone', phone);
                } else {
                  accountForm.setFieldValue('phone', phone);
                }
              }}
              items={[
                {
                  key: 'phoneCode',
                  label: '验证码登录',
                  children: (
                    <Form
                      name="phoneCode"
                      className={STYLE.phoneCodeForm}
                      form={phoneCodeForm}
                      initialValues={{ phone, agreement: true }}
                      onValuesChange={(changedValues) => {
                        if ('phone' in changedValues) {
                          setPhone?.(changedValues.phone);
                        }
                      }}
                      onFinish={({ phone, code }) => {
                        runPhoneCodeLogin({ phone, code })
                          .then(() => {
                            message.success('登录成功');
                            onSuccess();
                          })
                          .catch((e) => {
                            if (e.code === 102) {
                              phoneCodeForm.setFields([{ name: 'code', errors: [e.msg] }]);
                            } else {
                              message.error(e.msg);
                            }
                          });
                      }}
                    >
                      <PhoneAndCodeFormItem form={phoneCodeForm} />

                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        // rules={[{ required: true, message: '请同意用户协议以及隐私政策' }]}
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject('请同意用户协议以及隐私政策'),
                          },
                        ]}
                      >
                        <AgreementCheck />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          className={STYLE.submit}
                          type="primary"
                          htmlType="submit"
                          block
                          loading={phoneCodeLoginLoading}
                        >
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  ),
                },
                {
                  key: 'account',
                  label: '密码登录',
                  children: (
                    <Form
                      name="account"
                      form={accountForm}
                      className={STYLE.accountForm}
                      initialValues={{ phone, agreement: true }}
                      onValuesChange={(changedValues) => {
                        if ('phone' in changedValues) {
                          setPhone?.(changedValues.phone);
                        }
                      }}
                      onFinish={({ phone, password }) => {
                        runPhonePasswordLogin({ phone, password })
                          .then(() => {
                            message.success('登录成功');
                            onSuccess();
                          })
                          .catch((e) => message.error(e.msg));
                      }}
                    >
                      <Form.Item<LoginFormField>
                        name="phone"
                        rules={[{ required: true, message: '请输入手机号' }]}
                      >
                        <Input placeholder="请输入手机号" type="tel" autoComplete="tel-national" />
                      </Form.Item>

                      <Form.Item<LoginFormField>
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                        extra={
                          <Button
                            className={STYLE.forgetBtn}
                            type="text"
                            size="small"
                            onClick={() => setCurPage('changePW')}
                          >
                            忘记密码？
                          </Button>
                        }
                      >
                        <Input.Password placeholder="请输入密码" autoComplete="current-password" />
                      </Form.Item>

                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject('请同意用户协议以及隐私政策'),
                          },
                        ]}
                      >
                        <AgreementCheck />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          className={STYLE.submit}
                          type="primary"
                          htmlType="submit"
                          block
                          loading={phonePasswordLoginLoading}
                        >
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  ),
                },
              ]}
            />

            <p className={STYLE.toCreateAccount}>
              没有账号？
              <Button type="text" onClick={() => setCurPage('signUp')}>
                创建账号
              </Button>
            </p>
          </div>
        )}
      </div>
      <div className={STYLE.typeSwitch}>
        <div className="flexCenter">
          <Button
            type="text"
            icon={<Icon name="QQ" />}
            loading={getQQLoginUrlLoading}
            onClick={() => {
              qqWinRef.current = window.open('/loading', '_blank', getOpenWinCommonSetting());
              runGetQQLoginUrl()
                .then(({ url }) => (qqWinRef.current!.location.href = url))
                .catch(() => qqWinRef.current?.close());
            }}
          >
            QQ登录
          </Button>
        </div>
        <div className="flexCenter">
          {type === 'wechat' ? (
            <Button type="text" icon={<Icon name="shouji" />} onClick={() => setType('phoneCode')}>
              手机登录
            </Button>
          ) : (
            <Button
              type="text"
              icon={<Icon name="weixin" fill="#fff" />}
              onClick={() => setType('wechat')}
            >
              微信登录
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
