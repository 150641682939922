// 当前负责包装fetchApi使其始终携带cookie
// 以及登录弹窗相关逻辑
import { useEffect } from 'react';
import { useUpdateEffect } from 'ahooks';
import { commonShowLoginModal } from '@/coms/loginModal';
import userInfoStore from '@/stores/userInfo';
import fetchApi, { FetchApiExtraOptions, FetchApiOptions } from '@/utils/fetchApi';
import { observer } from 'mobx-react';
import type { NextComponentType, NextPageContext } from 'next';
import { modal } from '@/configs/theme';
import { commonShowRechargeModal } from '@/coms/recharge';
import { commonShowLimitModal } from '@/coms/limit';
import { flowResult } from 'mobx';
import { log } from 'console';

let loginModalHandlerKeeper: ReturnType<typeof modal.info> | undefined;
let rechargeModalHandlerKeeper: ReturnType<typeof modal.info> | undefined;
let limitModalHandlerKeeper: ReturnType<typeof modal.info> | undefined;

export default function withUserInfo<T extends {} = {}>(
  Com: NextComponentType<NextPageContext, any, T>,
  { alertLogin = false } = {},
) {
  const UserInfoLayout: NextComponentType<NextPageContext, any, T> = observer((props) => {
    const {
      isLogged,
      loginShow,
      toggleLoginShow,
      rechargeType,
      updateRechargeType,
      limitType,
      updateLimitType,
      logout,
      userInfo,
      updateUserInfo,
      checkRights,
    } = userInfoStore;

    useEffect(() => {
      if (alertLogin && isLogged === 'logout') {
        toggleLoginShow(true);
      }
    }, [isLogged]);

    useUpdateEffect(() => {
      console.log(loginShow, 'loginShowloginShowloginShow')
      if (loginShow && !loginModalHandlerKeeper) {
        loginModalHandlerKeeper = commonShowLoginModal({
          onSuccess: () => {
            flowResult(updateUserInfo()).then(() => checkRights(undefined, ['phone']));
            toggleLoginShow(false);
          },
          onClose: () => toggleLoginShow(false),
        });
      }
      if (!loginShow && loginModalHandlerKeeper) {
        loginModalHandlerKeeper.destroy();
        loginModalHandlerKeeper = undefined;
      }
    }, [loginShow]);

    useUpdateEffect(() => {
      if (rechargeType && !rechargeModalHandlerKeeper) {
        rechargeModalHandlerKeeper = commonShowRechargeModal({
          onSuccess: () => {
            updateUserInfo();
            updateRechargeType();
          },
          onClose: () => updateRechargeType(),
          type: rechargeType,
        });
      }
      if (!rechargeType && rechargeModalHandlerKeeper) {
        rechargeModalHandlerKeeper.destroy();
        rechargeModalHandlerKeeper = undefined;
      }
    }, [rechargeType]);

    useUpdateEffect(() => {
      if (limitType && !limitModalHandlerKeeper) {
        limitModalHandlerKeeper = commonShowLimitModal({
          onSuccess: () => {
            updateUserInfo();
            updateLimitType();
          },
          onClose: () => updateLimitType(),
          type: limitType,
        });
      }
      if (!limitType && limitModalHandlerKeeper) {
        limitModalHandlerKeeper.destroy();
        limitModalHandlerKeeper = undefined;
      }
    }, [limitType]);

    return <Com {...props} />;
  });

  // 如layout需要在服务端获取props，则在此拦截Com.getInitialProps并添加逻辑
  // 不需要则传递getInitialProps即可
  UserInfoLayout.getInitialProps = injectUserInfoLayoutPageContext(Com.getInitialProps);

  return UserInfoLayout;
}

export function makeWithUserInfoDecoratedFetchApi<T>(ctx: NextPageContext): typeof fetchApi<T> {
  return (
    url: string,
    options?: FetchApiOptions,
    fetchApiExtraOptions?: FetchApiExtraOptions<T>,
  ) => {
    const _options: FetchApiOptions = { ...options };
    if (ctx.req) {
      // 服务端请求时，将cookie带上
      _options.headers = {
        cookie: ctx.req.headers.cookie || '',
        ...(options?.headers || {}),
      };
    }

    return fetchApi<T>(url, _options, fetchApiExtraOptions);
  };
}

export interface UserInfoLayoutPageContext extends NextPageContext {
  decoratedFetchApi: typeof fetchApi;
}

const injectedUserInfoLayoutPageContextSymbol = Symbol('injectedUserInfoLayoutPageContext');

export interface UserInfoLayoutGetInitialProps {
  readonly [injectedUserInfoLayoutPageContextSymbol]: true;
  (ctx: UserInfoLayoutPageContext): {} | Promise<{}>;
}

export function injectUserInfoLayoutPageContext(
  getInitialProps?: NextComponentType['getInitialProps'] | UserInfoLayoutGetInitialProps,
): UserInfoLayoutGetInitialProps | undefined {
  if (!getInitialProps) {
    return undefined;
  }

  if ((getInitialProps as any)[injectedUserInfoLayoutPageContextSymbol]) {
    return getInitialProps as UserInfoLayoutGetInitialProps;
  }

  async function userInfoLayoutGetInitialProps(ctx: NextPageContext) {
    return getInitialProps!({
      ...ctx,
      decoratedFetchApi: makeWithUserInfoDecoratedFetchApi(ctx),
    });
  }

  (userInfoLayoutGetInitialProps as any)[injectedUserInfoLayoutPageContextSymbol] = true;
  return userInfoLayoutGetInitialProps as unknown as UserInfoLayoutGetInitialProps;
}
