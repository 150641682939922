import { useEffect, useRef, useState } from 'react';
import STYLE from './index.module.less';
import { observer } from 'mobx-react';
import CloseBtn from '../utils/closeBtn';
import { message, modal } from '@/configs/theme';
import Vip from './vip';
import Ai from './ai';
import userInfoStore from '@/stores/userInfo';
import { getPayPriceList, loopCheck, startPay } from '@/services/recharge';
import clx from 'classnames';
import { getExtraMap } from '@/utils/buryPoint/extra';

export interface PriceItem {
  id: number;
  price: number;
  [key: string]: any;
}

function RechargeModal({
  onSuccess = () => {},
  type,
}: {
  onSuccess: () => void;
  type: Exclude<typeof userInfoStore.rechargeType, false>;
}) {
  const { head_portrait, show_uid, username, nickname } = userInfoStore;

  const [priceList, setPriceList] = useState([]);
  const [currentPrice, setCurrentPrice] = useState<PriceItem>({ id: 0, price: 0 });
  const [pay_url, setPayUrl] = useState('');

  const timer = useRef<any>(null);

  useEffect(() => {
    if (type) {
      getPriceList();
    }
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [type]);

  useEffect(() => {
    if (currentPrice?.id) {
      timer.current && clearTimeout(timer.current);
      startPay({
        price_id: currentPrice?.id,
        coupon_id: 0,
        model_id: getExtraMap().model_id,
        bot_id: getExtraMap().bot_id,
      }).then((data) => {
        const { code_url, out_trade_no } = data;
        setPayUrl(code_url);
        pollingCheckPay(out_trade_no);
      });
    }
  }, [currentPrice?.id]);

  const getPriceList = () => {
    getPayPriceList({ show_page: type === 'vip' ? 'vip' : 'word' }).then((data) => {
      const { tab_list } = data;
      if (tab_list.length) {
        const list = tab_list[0]?.list || [];
        let price;
        for (let i = 0; i < list.length; i++) {
          if (list[i]?.is_default === 1) {
            price = list[i];
            break;
          }
        }
        if (!price) {
          price = list[0];
        }
        setPriceList(list);
        setCurrentPrice(price);
      }
    });
  };

  // 轮询检查支付结果
  const pollingCheckPay = (out_trade_no: string) => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      loopCheck({ out_trade_no })
        .then((data) => {
          if (data.pay_status) {
            onSuccess();
            message.success('支付成功');
          } else {
            pollingCheckPay(out_trade_no);
          }
        })
        .catch((e) => console.log(e));
    }, 2000);
  };

  const createRecharge = () => {
    let Recharge;
    switch (type) {
      case 'vip':
        Recharge = Vip;
        break;
      case 'wordNum':
      case 'num':
        Recharge = Ai;
        break;
    }
    if (!Recharge) return null;
    return (
      <Recharge
        username={nickname || username || show_uid}
        head_portrait={head_portrait}
        priceList={priceList}
        currentPrice={currentPrice}
        show_uid={show_uid}
        setCurrentPrice={setCurrentPrice}
        pay_url={pay_url}
      />
    );
  };

  return (
    <div className={clx(STYLE.root, { [STYLE.root_vip]: type === 'vip' })}>
      <CloseBtn onClick={() => userInfoStore.updateRechargeType()} />
      {createRecharge()}
    </div>
  );
}

export default observer(RechargeModal);

export function commonShowRechargeModal({
  onSuccess,
  onClose,
  type,
}: {
  onSuccess: () => void;
  onClose: () => void;
  type: Exclude<typeof userInfoStore.rechargeType, false>;
}) {
  return modal.info({
    content: <RechargeModal onSuccess={onSuccess} type={type} />,
    closeIcon: false,
    footer: null,
    icon: null,
    centered: true,
    width: type === 'vip' ? 890 : 814,
    className: 'clearAntdModalContentPadding hiddenAntdModalContentOverflow',
    afterClose: onClose,
  });
}
