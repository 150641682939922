import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import VipLabel from '@/coms/utils/vipLabel';
import AINumberLabel from '@/coms/utils/aiNumberLabel';
import PriceOption from './priceOption';
import Payment from '../coms/payment';
import { PriceItem } from '..';
import UserHeadPortrait from '@/coms/utils/userHeadPortrait';
import Image from "next/image";

function Vip({
  priceList,
  currentPrice,
  show_uid,
  pay_url,
  username,
  setCurrentPrice,
}: {
  head_portrait: string;
  priceList: any[];
  currentPrice: PriceItem;
  show_uid: string;
  pay_url: string;
  username: string;
  setCurrentPrice: (item: any) => void;
}) {
  return (
    <div className={STYLE.root}>
      <div className={STYLE.left}>
        <div className={STYLE.background} />
        <div className={STYLE.top}>
          <Image style={{height: 32, width: 104}} src={require('./../../../assets/image/logo.png')} alt="blur bg" />
          <div className={STYLE.rights}>会员卡权益介绍</div>
          <div className={STYLE.divider} />
        </div>
        <div className={STYLE.bottom}>
          <div className={STYLE.item}>
            <div className={STYLE.title}>
              <Icon name="quanzhankeyong" size={16} style={{ marginRight: '4px' }} />
              全站模型可用
            </div>
            <div className={STYLE.text}>可以使用所有模型进行对话聊天</div>
          </div>
          <div className={STYLE.item}>
            <div className={STYLE.title}>
              <Icon name="duomoxingliaotian-anjocm3o" size={16} style={{ marginRight: '4px' }} />
              全站机器人可用
            </div>
            <div className={STYLE.text}>可以使用所有机器人进行对话聊天</div>
          </div>
          <div className={STYLE.item}>
            <div className={STYLE.title}>
              <Icon name="hewenjianduihua" size={16} style={{ marginRight: '4px' }} />
              多模型PK布局切换
            </div>
            <div className={STYLE.text}>提供了4种常见布局模式，可以按照自己的喜好切换布局模式</div>
          </div>
          <div className={STYLE.item}>
            <div className={STYLE.title}>
              <Icon name="shangchuantubiao" size={12} style={{ marginRight: '4px' }} fill="#fff" />
              与文件对话可用
            </div>
            <div className={STYLE.text}>上传PDF、word、txt文档，并针对文档提问</div>
          </div>
        </div>
      </div>
      <div className={STYLE.right}>
        <div className={STYLE.right_top}>
          <UserHeadPortrait style={{ borderRadius: '50%' }} />
          <div className={STYLE.right_right}>
            <div className={STYLE.right_right_top}>
              <div className={STYLE.username}>{username}</div>
              <VipLabel />
              <AINumberLabel style={{ height: '20px' }} />
            </div>
            <div className={STYLE.user_show_uid}>{`ID:${show_uid}`}</div>
          </div>
        </div>
        <div className={STYLE.right_middle}>
          {priceList.map((item, index) => {
            return (
              <PriceOption
                key={index}
                selected={currentPrice.id === item.id}
                price={item.price}
                name={item.name}
                title={item.title}
                add_ai_word_num={item.add_ai_word_num}
                add_ai_num={item.add_ai_num}
                recommend={!!item.recommendText || index === 0}
                recommendText={item.recommendText}
                onClick={() => setCurrentPrice(item)}
              />
            );
          })}
        </div>
        <div className={STYLE.right_bottom}>
          <Payment pay_url={pay_url} price={currentPrice.price} />
        </div>
        <div className={STYLE.background_topLeft} />
        <div className={STYLE.background_bottomRight} />
      </div>
    </div>
  );
}

export default Vip;
