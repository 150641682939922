import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import { Checkbox, QRCode } from 'antd';
import { userAgreementUrl, wordRechargeAgreementUrl } from '@/configs/url';

function Payment({
  pay_url,
  price = 0,
  sign = '¥',
  forAI,
}: {
  pay_url?: string;
  price?: number;
  sign?: string;
  forAI?: boolean;
}) {
  return (
    <div className={STYLE.root}>
      <div className={STYLE.left}>
        <QRCode className={STYLE.qrcode} value={pay_url || '-'} size={156} />
        <div className={STYLE.payment_method}>
          <Icon name={'weixin-anjp338c'} size={16} style={{ marginRight: '6px' }} />
          <Icon name={'zhifubao'} size={16} style={{ marginRight: '6px' }} />
          扫码支付
        </div>
      </div>
      <div className={STYLE.right}>
        <div className={STYLE.right_top}>需支付: </div>
        <div className={STYLE.price}>
          <div className={STYLE.sign}>{sign}</div>
          {price}
        </div>
        <div className={STYLE.right_bottom}>
          <Checkbox checked>
            <span className={STYLE.agreement}>
              同意并接受
              {forAI ? (
                <a href={wordRechargeAgreementUrl}>《字数充值服务协议》</a>
              ) : (
                <a href={userAgreementUrl}>《用户协议》</a>
              )}
            </span>
          </Checkbox>
        </div>
      </div>
    </div>
  );
}

export default Payment;
