import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import { observer } from 'mobx-react';
import userInfoStore from '@/stores/userInfo';
import clx from 'classnames';

function VipLabel() {
  const { isVip } = userInfoStore;
  return (
    <div className={clx(STYLE.root, { [STYLE.root_vip]: isVip })}>
      <Icon
        name={isVip ? 'huiyuan' : 'huiyuanweikaitong'}
        size={16}
        style={{ marginRight: '4px' }}
      />
      <span className={clx(STYLE.status, { [STYLE.status_vip]: isVip })}>
        {isVip ? '会员' : '暂未开通'}
      </span>
    </div>
  );
}

export default observer(VipLabel);
