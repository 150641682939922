import STYLE from './index.module.less';
import userInfoStore from '@/stores/userInfo';
import Icon from '@/coms/icon';
import { observer } from 'mobx-react';
import clx from 'classnames';
import { setExtraMap } from '@/utils/buryPoint/extra';
import { Popover } from 'antd';
import commonStore from '@/stores/common';
import { buryPointMemo } from '@/utils/buryPoint';

function AINumberLabel({
  style = {},
  iconSize = 16,
  needRecharge = false,
  needBackground = true,
  position,
  fold,
  aiType = 'time',
}: {
  style?: React.CSSProperties;
  iconSize?: number;
  needRecharge?: boolean;
  needBackground?: boolean;
  position?: number;
  fold?: boolean;
  aiType?: 'number' | 'time' | 'all';
}) {
  const { ai_word_num, ai_num } = userInfoStore;
  const { AINumberLabelOpen: open } = commonStore;

  function Number() {
    return (
      <span className={STYLE.number}>
        {(() => {
          return `剩余AI字数：${ai_word_num}`;
        })()}
      </span>
    );
  }

  function Recharge({ type = 'num' }: { type?: 'num' | 'workNum' } = {}) {
    if (aiType === 'number') return null;
    return (
      <div
        className={STYLE.recharge}
        onClick={() => {
          switch (position) {
            case 1:
              setExtraMap({ stats_position: '主站-左下角-充值' });
              break;
            case 2:
              setExtraMap({ stats_position: '主站头像下拉-充值' });
              break;
          }
          if (!userInfoStore.checkHaveRights(['vip'], { alert: true })) {
            return;
          }
          buryPointMemo('AI字数充值弹窗');
          userInfoStore.updateRechargeType(type as any);
        }}
      >
        充值
      </div>
    );
  }

  return (
    <Popover
      placement="right"
      open={!!(fold && open)}
      content={
        <div className={STYLE.popover_text}>
          <Number />
          <Recharge />
        </div>
      }
      zIndex={1000}
      overlayClassName={STYLE.popover}
    >
      <div
        className={clx(
          STYLE.root,
          {
            [STYLE.root_bg]: needBackground,
          },
          fold
            ? {
                [STYLE.root_fold]: !open,
                [STYLE.root_open]: open,
              }
            : null,
        )}
        style={{ ...style, cursor: fold ? 'pointer' : undefined }}
        onClick={() => commonStore.toggleAINumberLabelOpen(!open)}
      >
        <div className={STYLE.left}>
          <Icon name={'AIzishu'} size={iconSize} style={{ marginRight: '2px' }} />
          {!fold && <Number />}
        </div>
        {needRecharge && !fold ? <Recharge /> : null}
      </div>
    </Popover>
  );
}

export default observer(AINumberLabel);
