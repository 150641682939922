import { AgreementCheck, LoginFormField, PageProps, PhoneAndCodeFormItem } from '../common';
import clx from 'classnames';
import STYLE from './index.module.less';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useRequest } from 'ahooks';
import { phonePasswordLogin } from '@/services/user';
import { message } from '@/configs/theme';

interface SignUpAndChangePWPageProps extends PageProps {
  type: 'signUp' | 'changePW';
}

export default function SignUpAndChangePWPage({
  type,
  setCurPage,
  onSuccess,
  className,
  phone,
  setPhone,
}: SignUpAndChangePWPageProps) {
  const [form] = Form.useForm<LoginFormField>();

  const { runAsync: runPhonePasswordLogin, loading: phonePasswordLoginLoading } = useRequest(
    phonePasswordLogin,
    { manual: true },
  );

  return (
    <div className={clx(className, STYLE.signUpAndChangePWPage)}>
      <div className={STYLE.view}>
        <Button
          className={STYLE.back}
          type="text"
          icon={<LeftOutlined />}
          size="small"
          onClick={() => setCurPage('login')}
        >
          {type === 'signUp' ? '注册' : '忘记密码'}
        </Button>

        <Form
          name="signUpAndChangePW"
          className={STYLE.form}
          form={form}
          initialValues={{ phone, agreement: true }}
          onValuesChange={(changedValues) => {
            if ('phone' in changedValues) {
              setPhone?.(changedValues.phone);
            }
          }}
          onFinish={({ phone, code, password }) => {
            runPhonePasswordLogin({ phone, code, password, isChangingPW: type === 'changePW' })
              .then(() => {
                message.success('登录成功');
                onSuccess();
              })
              .catch((e) => {
                if (e.code === 102) {
                  form.setFields([{ name: 'code', errors: [e.msg] }]);
                } else {
                  message.error(e.msg);
                }
              });
          }}
        >
          <PhoneAndCodeFormItem form={form} />

          <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
            <Input.Password
              placeholder="请设置6-12位密码"
              type="password"
              autoComplete="new-password"
              maxLength={12}
            />
          </Form.Item>

          {type === 'changePW' ? (
            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true, message: '请确认密码' },
                {
                  validator: (_, value) =>
                    value === form.getFieldValue('password')
                      ? Promise.resolve()
                      : Promise.reject('两次密码不一致'),
                },
              ]}
            >
              <Input.Password
                placeholder="确认密码"
                type="password"
                autoComplete="new-password"
                maxLength={12}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject('请同意用户协议以及隐私政策'),
                },
              ]}
            >
              <AgreementCheck />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              className={STYLE.submit}
              type="primary"
              htmlType="submit"
              block
              loading={phonePasswordLoginLoading}
            >
              {type === 'signUp' ? '注册并登录' : '确认修改并登录'}
            </Button>
          </Form.Item>
        </Form>

        {type === 'changePW' && (
          <p className={STYLE.toCreateAccount}>
            没有账号？
            <Button type="text" onClick={() => setCurPage('signUp')}>
              创建账号
            </Button>
          </p>
        )}
      </div>
    </div>
  );
}
