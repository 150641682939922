import STYLE from './index.module.less';
import Icon from '@/coms/icon';
import clx from 'classnames';

function PriceOption({
  selected,
  recommend,
  forAI,
  add_ai_word_num,
  add_ai_num,
  price,
  name,
  title,
  sign = '¥',
  recommendText = '全站模型、AI畅用',
  onClick,
}: {
  selected: boolean;
  recommend?: boolean;
  forAI?: boolean;
  add_ai_word_num?: number;
  add_ai_num?: number;
  price?: number;
  name?: string;
  title?: number;
  sign?: string;
  recommendText?: string;
  onClick: () => void;
}) {
  const instructions = [
    { icon: 'moxing', text: '所有模型可用' },
    {
      icon: 'danseAI',
      text: '不限次对话',
    },
  ];

  return (
    <div
      className={
        !forAI
          ? clx(STYLE.root, {
              [STYLE.root_recommend]: recommend,
              [STYLE.root_selected]: selected,
            })
          : clx(STYLE.root_ai, {
              [STYLE.root_selected]: selected,
            })
      }
      onClick={onClick}
    >
      {!forAI ? (
        <>
          <div className={STYLE.top}>{name}</div>
          <div className={STYLE.middle}>
            <div className={STYLE.price}>
              <div className={STYLE.sign}>{sign}</div>
              {price}
            </div>
          </div>
          <div className={clx(STYLE.bottom, { [STYLE.bottom_recommend]: recommend })}>
            {instructions.map((item, index) => {
              return (
                <div key={index} className={STYLE.item}>
                  <Icon name={item.icon} size={14} style={{ marginRight: '4px' }} />
                  {item.text}
                </div>
              );
            })}
          </div>
          {recommend ? <div className={STYLE.recommend}>{recommendText}</div> : null}
        </>
      ) : (
        <>
          <div className={STYLE.top}>
            <Icon name={'AIzishu'} size={16} style={{ marginRight: '4px' }} />
             字数
            {/*次对话*/}
          </div>
           <div className={STYLE.middle}>{add_ai_word_num || title}字</div>
          {/*<div className={STYLE.middle}>{add_ai_num || title}</div>*/}
          <div className={clx(STYLE.divider, { [STYLE.divider_selected]: selected })} />
          <div className={STYLE.price}>
            <div className={STYLE.sign}>{sign}</div>
            {price}
          </div>
        </>
      )}
      {selected ? (
        <div className={STYLE.selected}>
          <Icon name="wancheng" size={20} fill={'#fff'} />
        </div>
      ) : null}
    </div>
  );
}

export default PriceOption;
