import { useState } from 'react';
import STYLE from './index.module.less';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import userInfoStore from '@/stores/userInfo';
import Image from 'next/image';
import { Page, PageProps } from './common';
import LoginPage from './login';
import SignUpAndChangePWPage from './signUpAndChangePW';
import { modal } from '@/configs/theme';
import Icon from '../icon';

const LoginModal = observer(({ onSuccess = () => {} }) => {
  const [curPage, setCurPage] = useState<Page>('login');
  const [phone, setPhone] = useState<string>('');

  const pageProps: PageProps = { setCurPage, onSuccess, className: STYLE.page, phone, setPhone };

  return (
    <div className={STYLE.root}>
      <Button
        className={STYLE.closeBtn}
        type="text"
        icon={<CloseOutlined />}
        onClick={() => userInfoStore.toggleLoginShow(false)}
      />

      <div className={STYLE.blurBg}>
        <Image style={{height: 32, width: 104, marginTop: 16, marginLeft: 16}} src={require('./../../assets/image/logo.png')} alt="blur bg" />
        <Image src={require('./ellipse1.png')} alt="blur bg" />
        <Image src={require('./ellipse2.png')} alt="blur bg" />
      </div>

      <div
        className={STYLE.pageCon}
        style={{ transform: `translate(${curPage === 'login' ? '0' : '-50%'})` }}
      >
        <LoginPage {...pageProps} />
        {curPage !== 'login' && <SignUpAndChangePWPage {...pageProps} type={curPage} />}
      </div>
    </div>
  );
});

export default LoginModal;

export function commonShowLoginModal({
  onSuccess,
  onClose,
}: {
  onSuccess: () => void;
  onClose?: () => void;
}) {
  return modal.info({
    content: <LoginModal onSuccess={onSuccess} />,
    closeIcon: false,
    footer: null,
    icon: null,
    centered: true,
    width: 390,
    className: 'clearAntdModalContentPadding hiddenAntdModalContentOverflow',
    afterClose: onClose,
  });
}
