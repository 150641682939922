import { observer } from 'mobx-react';
import userInfoStore from '@/stores/userInfo';
import Image from 'next/image';

function UserHeadPortrait({ width = 40, height = 40, style = {}, ...args }) {
  const { head_portrait } = userInfoStore;

  return (
    <Image
      src={head_portrait}
      alt={'head_portrait'}
      width={width}
      height={height}
      style={{ ...style }}
      {...args}
    />
  );
}

export default observer(UserHeadPortrait);
